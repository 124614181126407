

// import project1 from "./assets/matt-projects/project-1.jpg"
// import project2 from "./assets/matt-projects/project-2.jpg"
// import project3 from "./assets/matt-projects/project-3.jpg"
import project4 from "./assets/matt-projects/project-4.jpg"
import project5 from "./assets/matt-projects/project-5.jpg"
import project6 from "./assets/matt-projects/project-6.jpg"
import project7 from "./assets/matt-projects/project-7.jpg"
import project8 from "./assets/matt-projects/project-8.jpg"
import project9 from "./assets/matt-projects/project-9.jpg"
import projec10 from "./assets/matt-projects/project-10.jpg"
import project11 from "./assets/matt-projects/project-11.jpg"
import project12 from "./assets/matt-projects/project-12.jpg"
import project13 from "./assets/matt-projects/project-13.jpg"
import project14 from "./assets/matt-projects/project-14.jpg"
import project15 from "./assets/matt-projects/project-15.jpg"
import project16 from "./assets/matt-projects/project-16.jpg"
import project17 from "./assets/matt-projects/project-17.jpg"
import project18 from "./assets/matt-projects/project-18.jpg"
import project19 from "./assets/matt-projects/project-19.jpg"
import project20 from "./assets/matt-projects/project-20.jpg"
import project21 from "./assets/matt-projects/project-21.jpg"
import project22 from "./assets/matt-projects/project-22.jpg"
import project23 from "./assets/matt-projects/project-23.jpg"
import project24 from "./assets/matt-projects/project-24.jpg"
import project25 from "./assets/matt-projects/project-25.jpg"
import project26 from "./assets/matt-projects/project-26.jpg"
import project27 from "./assets/matt-projects/project-27.jpg"
import project28 from "./assets/matt-projects/project-28.jpg"
// import project29 from "./assets/matt-projects/project-29.jpg"
import project30 from "./assets/matt-projects/project-30.jpg"
import project31 from "./assets/matt-projects/project-31.jpg"
import project32 from "./assets/matt-projects/project-32.jpg"
import project33 from "./assets/matt-projects/project-33.jpg"
import project34 from "./assets/matt-projects/project-34.jpg"
import project35 from "./assets/matt-projects/project-35.jpg"
import project36 from "./assets/matt-projects/project-36.jpg"
import project37 from "./assets/matt-projects/project-37.jpg"
import project38 from "./assets/matt-projects/project-38.jpg"
import project39 from "./assets/matt-projects/project-39.jpg"
import project40 from "./assets/matt-projects/project-40.jpg"
import project41 from "./assets/matt-projects/project-41.jpg"
import project42 from "./assets/matt-projects/project-42.jpg"
import project43 from "./assets/matt-projects/project-43.jpg"
import project44 from "./assets/matt-projects/project-44.jpg"
import project45 from "./assets/matt-projects/project-45.jpg"
import project46 from "./assets/matt-projects/project-46.jpg"
import project47 from "./assets/matt-projects/project-47.jpg"
import project48 from "./assets/matt-projects/project-48.jpg"
import project49 from "./assets/matt-projects/project-49.jpg"
import project50 from "./assets/matt-projects/project-50.jpg"
import project51 from "./assets/matt-projects/project-51.jpg"
import project52 from "./assets/matt-projects/project-52.jpg"
import project53 from "./assets/matt-projects/project-53.jpg"
import project54 from "./assets/matt-projects/project-54.jpg"
import project55 from "./assets/matt-projects/project-55.jpg"
import project56 from "./assets/matt-projects/project-56.jpg"
import project57 from "./assets/matt-projects/project-57.jpg"
import project58 from "./assets/matt-projects/project-58.jpg"
import project59 from "./assets/matt-projects/project-59.jpg"
import project60 from "./assets/matt-projects/project-60.jpg"
import project61 from "./assets/matt-projects/project-61.jpg"
import project62 from "./assets/matt-projects/project-62.jpg"
import project63 from "./assets/matt-projects/project-63.jpg"
import project64 from "./assets/matt-projects/project-64.jpg"
import project65 from "./assets/matt-projects/project-65.jpg"
import project66 from "./assets/matt-projects/project-66.jpg"
import project67 from "./assets/matt-projects/project-67.jpg"
import project68 from "./assets/matt-projects/project-68.jpg"
import project69 from "./assets/matt-projects/project-69.jpg"
import project70 from "./assets/matt-projects/project-70.jpg"
import project71 from "./assets/matt-projects/project-71.jpg"
import project72 from "./assets/matt-projects/project-72.jpg"
import project73 from "./assets/matt-projects/project-73.jpg"
import project74 from "./assets/matt-projects/project-74.jpg"
import project75 from "./assets/matt-projects/project-75.jpg"

// interiro
import pro76   from "./assets/matt-projects/pro76.jpg"
import pro77   from "./assets/matt-projects/pro77.jpg"
import pro78   from "./assets/matt-projects/pro78.jpg"
import pro79   from "./assets/matt-projects/pro79.jpg"
import pro80_1 from "./assets/matt-projects/pro80 (1).jpg"
import pro80_2 from "./assets/matt-projects/pro80 (2).jpg"

import pro81_1 from "./assets/matt-projects/pro81 (1).jpg"
import pro81_2 from "./assets/matt-projects/pro81 (2).jpg"
import pro81_3  from "./assets/matt-projects/pro81 (3).jpg"
import pro81_4  from "./assets/matt-projects/pro81 (4).jpg"
import pro81_5  from "./assets/matt-projects/pro81 (5).jpg"
import pro81_6  from "./assets/matt-projects/pro81 (6).jpg"
import pro81_7  from "./assets/matt-projects/pro81 (7).jpg"
import pro81_8  from "./assets/matt-projects/pro81 (8).jpg"
import pro81_9  from "./assets/matt-projects/pro81 (9).jpg"
import pro81_10 from "./assets/matt-projects/pro81 (10).jpg"

import pro81_11 from "./assets/matt-projects/pro81 (11).jpg"
import pro81_12 from "./assets/matt-projects/pro81 (12).jpg"
import pro81_13 from "./assets/matt-projects/pro81 (13).jpg"
import pro81_14 from "./assets/matt-projects/pro81 (14).jpg"
import pro81_15 from "./assets/matt-projects/pro81 (15).jpg"
import pro81_16 from "./assets/matt-projects/pro81 (16).jpg"
import pro81_17 from "./assets/matt-projects/pro81 (17).jpg"
import pro81_18 from "./assets/matt-projects/pro81 (18).jpg"

// intrrior
import pro82_1 from "./assets/matt-projects/pro82 (1).jpg"
import pro82_2 from "./assets/matt-projects/pro82 (2).jpg"
import pro82_3  from "./assets/matt-projects/pro82 (3).jpg"
import pro82_4  from "./assets/matt-projects/pro82 (4).jpg"
import pro82_5  from "./assets/matt-projects/pro82 (5).jpg"
import pro82_6  from "./assets/matt-projects/pro82 (6).jpg"
import pro82_7  from "./assets/matt-projects/pro82 (7).jpg"
import pro82_8  from "./assets/matt-projects/pro82 (8).jpg"
import pro82_9  from "./assets/matt-projects/pro82 (9).jpg"
import pro82_10 from "./assets/matt-projects/pro82 (10).jpg"
import pro82_11 from "./assets/matt-projects/pro82 (11).jpg"
import pro82_12 from "./assets/matt-projects/pro82 (12).jpg"
import pro82_13 from "./assets/matt-projects/pro82 (13).jpg"
import pro82_14 from "./assets/matt-projects/pro82 (14).jpg"
import pro82_15 from "./assets/matt-projects/pro82 (15).jpg"

// interior
import pro83_1 from "./assets/matt-projects/pro83 (1).jpg"
import pro83_2 from "./assets/matt-projects/pro83 (2).jpg"
import pro83_3  from "./assets/matt-projects/pro83 (3).jpg"
import pro83_4  from "./assets/matt-projects/pro83 (4).jpg"
import pro83_5  from "./assets/matt-projects/pro83 (5).jpg"
import pro83_6  from "./assets/matt-projects/pro83 (6).jpg"
import pro83_7  from "./assets/matt-projects/pro83 (7).jpg"
import pro83_8  from "./assets/matt-projects/pro83 (8).jpg"
import pro83_9  from "./assets/matt-projects/pro83 (9).jpg"
import pro83_10 from "./assets/matt-projects/pro83 (10).jpg"
import pro83_11 from "./assets/matt-projects/pro83 (11).jpg"
import pro83_12 from "./assets/matt-projects/pro83 (12).jpg"
import pro83_13 from "./assets/matt-projects/pro83 (13).jpg"
import pro83_14 from "./assets/matt-projects/pro83 (14).jpg"
import pro83_15 from "./assets/matt-projects/pro83 (15).jpg"
import pro83_16 from "./assets/matt-projects/pro83 (16).jpg"
import pro83_17 from "./assets/matt-projects/pro83 (17).jpg"
import pro83_18 from "./assets/matt-projects/pro83 (18).jpg"
import pro83_19 from "./assets/matt-projects/pro83 (19).jpg"
import pro83_20 from "./assets/matt-projects/pro83 (20).jpg"
import pro83_21 from "./assets/matt-projects/pro83 (21).jpg"

// interior
import pro84_1 from "./assets/matt-projects/pro84 (1).jpg"
import pro84_2 from "./assets/matt-projects/pro84 (2).jpg"
import pro84_3  from "./assets/matt-projects/pro84 (3).jpg"
import pro84_4  from "./assets/matt-projects/pro84 (4).jpg"
import pro84_5  from "./assets/matt-projects/pro84 (5).jpg"
import pro84_6  from "./assets/matt-projects/pro84 (6).jpg"
import pro84_7  from "./assets/matt-projects/pro84 (7).jpg"
import pro84_8  from "./assets/matt-projects/pro84 (8).jpg"
import pro84_9  from "./assets/matt-projects/pro84 (9).jpg"
import pro84_10 from "./assets/matt-projects/pro84 (10).jpg"
import pro84_11 from "./assets/matt-projects/pro84 (11).jpg"
import pro84_12 from "./assets/matt-projects/pro84 (12).jpg"
import pro84_13 from "./assets/matt-projects/pro84 (13).jpg"
import pro84_14 from "./assets/matt-projects/pro84 (14).jpg"
import pro84_15 from "./assets/matt-projects/pro84 (15).jpg"
import pro84_16 from "./assets/matt-projects/pro84 (16).jpg"
import pro84_17 from "./assets/matt-projects/pro84 (17).jpg"
import pro84_18 from "./assets/matt-projects/pro84 (18).jpg"
import pro84_19 from "./assets/matt-projects/pro84 (19).jpg"
import pro84_20 from "./assets/matt-projects/pro84 (20).jpg"

// interior
import pro85_1 from "./assets/matt-projects/pro85 (1).jpg"
import pro85_2 from "./assets/matt-projects/pro85 (2).jpg"
import pro85_3  from "./assets/matt-projects/pro85 (3).jpg"
import pro85_4  from "./assets/matt-projects/pro85 (4).jpg"
import pro85_5  from "./assets/matt-projects/pro85 (5).jpg"
import pro85_6  from "./assets/matt-projects/pro85 (6).jpg"
import pro85_7  from "./assets/matt-projects/pro85 (7).jpg"
import pro85_8  from "./assets/matt-projects/pro85 (8).jpg"
import pro85_9  from "./assets/matt-projects/pro85 (9).jpg"
import pro85_10 from "./assets/matt-projects/pro85 (10).jpg"
import pro85_11 from "./assets/matt-projects/pro85 (11).jpg"
import pro85_12 from "./assets/matt-projects/pro85 (12).jpg"
import pro85_13 from "./assets/matt-projects/pro85 (13).jpg"
import pro85_14 from "./assets/matt-projects/pro85 (14).jpg"
import pro85_15 from "./assets/matt-projects/pro85 (15).jpg"
import pro85_16 from "./assets/matt-projects/pro85 (16).jpg"
import pro85_17 from "./assets/matt-projects/pro85 (17).jpg"
import pro85_18 from "./assets/matt-projects/pro85 (18).jpg"
import pro85_19 from "./assets/matt-projects/pro85 (19).jpg"
import pro85_20 from "./assets/matt-projects/pro85 (20).jpg"
import pro85_21 from "./assets/matt-projects/pro85 (21).jpg"
import pro85_22 from "./assets/matt-projects/pro85 (22).jpg"
import pro85_23 from "./assets/matt-projects/pro85 (23).jpg"
import pro85_24 from "./assets/matt-projects/pro85 (24).jpg"
import pro85_25 from "./assets/matt-projects/pro85 (25).jpg"

// interior
import pro86_1 from "./assets/matt-projects/pro86 (1).jpg"
import pro86_2 from "./assets/matt-projects/pro86 (2).jpg"
import pro86_3  from "./assets/matt-projects/pro86 (3).jpg"
import pro86_4  from "./assets/matt-projects/pro86 (4).jpg"
import pro86_5  from "./assets/matt-projects/pro86 (5).jpg"
import pro86_6  from "./assets/matt-projects/pro86 (6).jpg"
import pro86_7  from "./assets/matt-projects/pro86 (7).jpg"
import pro86_8  from "./assets/matt-projects/pro86 (8).jpg"
import pro86_9  from "./assets/matt-projects/pro86 (9).jpg"
import pro86_10 from "./assets/matt-projects/pro86 (10).jpg"
import pro86_11 from "./assets/matt-projects/pro86 (11).jpg"
import pro86_12 from "./assets/matt-projects/pro86 (12).jpg"
import pro86_13 from "./assets/matt-projects/pro86 (13).jpg"
import pro86_14 from "./assets/matt-projects/pro86 (14).jpg"
import pro86_15 from "./assets/matt-projects/pro86 (15).jpg"
import pro86_16 from "./assets/matt-projects/pro86 (16).jpg"
import pro86_17 from "./assets/matt-projects/pro86 (17).jpg"
import pro86_18 from "./assets/matt-projects/pro86 (18).jpg"
import pro86_19 from "./assets/matt-projects/pro86 (19).jpg"
import pro86_20 from "./assets/matt-projects/pro86 (20).jpg"
import pro86_21 from "./assets/matt-projects/pro86 (21).jpg"
import pro86_22 from "./assets/matt-projects/pro86 (22).jpg"
import pro86_23 from "./assets/matt-projects/pro86 (23).jpg"
import pro86_24 from "./assets/matt-projects/pro86 (24).jpg"
import pro86_25 from "./assets/matt-projects/pro86 (25).jpg"
import pro86_26 from "./assets/matt-projects/pro86 (26).jpg"
import pro86_27 from "./assets/matt-projects/pro86 (27).jpg"

// interiro
import pro86_1png from "./assets/matt-projects/pro86 (1).png"
import pro86_2png from "./assets/matt-projects/pro86 (2).png"
import pro86_3png from "./assets/matt-projects/pro86 (3).png"
import pro86_4png from "./assets/matt-projects/pro86 (4).png"
import pro86_5png from "./assets/matt-projects/pro86 (5).png"
import pro86_6png from "./assets/matt-projects/pro86 (6).png"

// interiro
import pro87_2 from "./assets/matt-projects/pro87 (2).jpg"
import pro87_3  from "./assets/matt-projects/pro87 (3).jpg"
import pro87_4  from "./assets/matt-projects/pro87 (4).jpg"
import pro87_5  from "./assets/matt-projects/pro87 (5).jpg"
import pro87_6  from "./assets/matt-projects/pro87 (6).jpg"
import pro87_7  from "./assets/matt-projects/pro87 (7).jpg"
import pro87_8  from "./assets/matt-projects/pro87 (8).jpg"
import pro87_9  from "./assets/matt-projects/pro87 (9).jpg"
import pro87_10 from "./assets/matt-projects/pro87 (10).jpg"
import pro87_11 from "./assets/matt-projects/pro87 (11).jpg"
import pro87_12 from "./assets/matt-projects/pro87 (12).jpg"
import pro87_13 from "./assets/matt-projects/pro87 (13).jpg"
import pro87_14 from "./assets/matt-projects/pro87 (14).jpg"
import pro87_15 from "./assets/matt-projects/pro87 (15).jpg"
import pro87_16 from "./assets/matt-projects/pro87 (16).jpg"
import pro87_17 from "./assets/matt-projects/pro87 (17).jpg"
import pro87_18 from "./assets/matt-projects/pro87 (18).jpg"
import pro87_19 from "./assets/matt-projects/pro87 (19).jpg"
import pro87_20 from "./assets/matt-projects/pro87 (20).jpg"
import pro87_21 from "./assets/matt-projects/pro87 (21).jpg"
import pro87_22 from "./assets/matt-projects/pro87 (22).jpg"
import pro87_23 from "./assets/matt-projects/pro87 (23).jpg"
import pro87_24 from "./assets/matt-projects/pro87 (24).jpg"

// interiro
import pro88_1 from "./assets/matt-projects/pro88 (1).jpg"
import pro88_2 from "./assets/matt-projects/pro88 (2).jpg"
import pro88_3  from "./assets/matt-projects/pro88 (3).jpg"
import pro88_4  from "./assets/matt-projects/pro88 (4).jpg"
import pro88_5  from "./assets/matt-projects/pro88 (5).jpg"
import pro88_6  from "./assets/matt-projects/pro88 (6).jpg"
import pro88_7  from "./assets/matt-projects/pro88 (7).jpg"
import pro88_8  from "./assets/matt-projects/pro88 (8).jpg"
import pro88_9  from "./assets/matt-projects/pro88 (9).jpg"







export const dataInteriorImage =[
  pro88_1,
pro88_2,
pro84_11,
pro84_12,
pro84_13,
pro84_14,
pro84_15,
pro84_16,
pro84_17,
pro84_18,
pro84_19,
pro84_20,
  pro83_1, 
pro88_3,
pro88_4,
pro88_5,
pro88_6,
pro88_7,
pro88_8,
pro88_9,
  pro87_2 ,
  pro87_3 ,
  pro87_4 ,
  pro87_5 ,
  pro87_6 ,
  pro87_7 ,
  pro87_8 ,
  pro87_9 ,
  pro87_10,
  pro87_11,
  pro87_21,
 
  pro87_18,
  pro87_19,
  pro87_20,
  pro87_21,
  pro87_22,
  pro87_23,
  pro87_24,
pro86_2png ,
pro86_4png,
pro86_5png,
pro86_6png,
pro86_4 ,
pro86_7 ,
pro86_8 ,

pro86_15,
pro86_16,
pro86_17,

pro86_19,

pro86_21,






  pro85_1, 
pro85_2 ,
pro85_3 ,
pro85_4 ,
pro85_5 ,
pro85_6 ,
pro85_7 ,
pro85_8 ,
pro85_9 ,
pro85_10,
pro85_11,
pro85_12,
pro85_13,
pro85_14,
pro85_15,
pro85_16,
pro85_17,
pro85_18,
pro85_19,
pro85_20,
pro85_21,
pro85_22,
pro85_23,
pro87_13,
pro87_14,
pro87_15,
pro87_16,
pro87_17,
pro85_24,
pro85_25,
  pro84_1, 
pro84_2 ,
pro84_3 ,
pro84_4 ,
pro84_5 ,
pro84_6 ,
pro84_7 ,
pro84_8 ,
pro84_9 ,
pro84_10,

pro83_2 ,
pro83_3 ,
pro83_4 ,
pro83_5 ,
pro83_6 ,
pro83_7 ,
pro83_8 ,
pro83_9 ,
pro83_10,
pro83_11,
pro83_12,
pro83_13,
pro83_14,
pro83_15,
pro83_16,
pro83_17,
pro83_18,
pro83_19,
pro83_20,
pro83_21,
  pro82_1, 
pro82_2 ,
pro82_3 ,
pro82_4 ,
pro82_5 ,
pro82_6 ,
pro82_7 ,
pro82_8 ,
pro82_9 ,
pro82_10,
pro82_11,
pro82_12,
pro82_13,
pro82_14,
pro82_15,
  pro81_11,
pro81_12,
pro81_13,
pro81_14,
pro81_15,
pro81_16,
pro81_17,
pro81_18,
  pro81_1, 
pro81_2 ,
pro81_3 ,
pro81_4 ,
pro81_5 ,
pro81_6 ,
pro81_7 ,
pro81_8 ,
pro81_9 ,
pro81_10,
  pro76 , 
pro77  ,
pro78  ,
pro79  ,
pro80_1,
pro80_2,
    project19,
    project20,
    project21,
    project22,
    project23,
    project24,
    project49,
project50,
project51,
project52,
project53,
project54,
project55,
project56,
project57,
project58,
project59,
project60,
project61,
project63,
project64,
project65,
project66,
project67,
project68,
project69,
project70,
project75,
  
  ]




export const dataDigitalBestSeller = [

  {
    id: 0,
    english: 'Design of Salah Al-Din Governorate Council Project',
    arabic: 'مشروع تصميم مجلس محافظة صلاح الدين',
    mainIMG: project17,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    english: '',
    arabic: '',
    mainIMG: pro88_8,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 17,
    english: 'Interior and exterior design and landscape with implementation for a villa in Salah Al-Din',
    arabic: "تصميم خارجي وداخلي و لاند سكيب مع التنفيذ لفيلا في صلاح الدين",
    mainIMG: project45,
    interior:[],
    exterior:[],
    landscap:[]
  },


      {
    id: 20,
    english: "Interior and exterior design with implementation for a villa in Tikrit-Salah Al-Din",
    arabic: "تصميم خارجي وداخلي مع التنفيذ لفيلا في صلاح الدين-تكريت",
    mainIMG: project24,
    interior:[],
    exterior:[],
    landscap:[]
  },
{
    id: 21,
    english: "Interior design for a house in Baiji-Salah Al-Din",
    arabic: "تصميم داخلي لبيت مشيد في صلاح الدين-بيجي",
    mainIMG: project25,
    exterior:[],
    interior:[],
    landscap:[]
  },

  {
    id: 22,
    english: "",
    arabic: "",
    mainIMG: project26,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 23,
    english: "Interior design for the main sales center of Asiacell Telecom Company in Kirkuk",
    arabic: "تصميم داخلي للمركز الرئيسي للمبيعات الخاص بشركة اسيا سيل للاتصالات في كركوك",
    mainIMG: project27,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 24,
    english: "Design and implementation of Al-Mukhtar Central Pharmacy in Kirkuk",
    arabic: "تصميم وتنفيذ صيدلية المختار المركزية في كركوك",
    mainIMG: project28,
    interior:[],
    exterior:[],
    landscap:[]
  },

 

  {
    id: 26,
    english: "",
    arabic: "",
    mainIMG: project30,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 19,
    english: "Exterior design for a villa in Erbil",
    arabic: "تصميم خارجي وداخلي مع التنفيذ لفيلا في صلاح الدين",
    mainIMG: project23,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 27,
    english: "",
    arabic: "",
    mainIMG: project31,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 28,
    english: "",
    arabic: "",
    mainIMG: project32,
    interior:[],
    exterior:[],
    landscap:[]
  },
  

  {
    id: 29,
    english: "",
    arabic: "",
    mainIMG: project33,
    interior:[],
    exterior:[],
    landscap:[]
  },

 

  {
    english: '',
    arabic: '',
    mainIMG: pro87_22,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 18,
    english: 'Interior and exterior design with implementation for a villa in Salah Al-Din',
    arabic: "تصميم خارجي وداخلي مع التنفيذ لفيلا في صلاح الدين",
    mainIMG: project22,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    english: '',
    arabic: '',
    mainIMG: pro87_21,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    english: '',
    arabic: '',
    mainIMG: pro87_20,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_19,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_18,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_17,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_16,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_15,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_14,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_13,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_12,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 62,
    english: "",
    arabic: "",
    mainIMG: project66,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 63,
    english: "",
    arabic: "",
    mainIMG: project67,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 65,
    english: "",
    arabic: "",
    mainIMG: project69,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 66,
    english: "",
    arabic: "",
    mainIMG: project70,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 67,
    english: "",
    arabic: "",
    mainIMG: project71,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 68,
    english: "",
    arabic: "",
    mainIMG: project72,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 69,
    english: "",
    arabic: "",
    mainIMG: project73,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 70,
    english: "",
    arabic: "",
    mainIMG: project74,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 71,
    english: "",
    arabic: "",
    mainIMG: project75,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_11,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_10,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_9,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_8,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_7,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_6,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_5,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_4,
    interior:[],
    exterior:[],
    landscap:[]
  },
  
  {
    english: '',
    arabic: '',
    mainIMG: pro87_3,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_2,
    interior:[],
    exterior:[],
    landscap:[]
  },


  {
    english: '',
    arabic: '',
    mainIMG: pro86_27,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_26,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_25,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_24,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_23,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_22,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_21,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    english: '',
    arabic: '',
    mainIMG: pro86_20,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_19,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_18,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_17,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_16,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_15,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_14,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_13,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_12,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_11,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_10,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_9,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_8,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_7,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_6,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_5,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_4,
    interior:[],
    exterior:[],
    landscap:[]
  },
  
  {
    english: '',
    arabic: '',
    mainIMG: pro86_3,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_2,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_1,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_1png,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro86_2png,
    interior:[],
    exterior:[],
    landscap:[]
  },
 
  {
    english: '',
    arabic: '',
    mainIMG: pro85_11,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_10,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_9,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 49,
    english: "",
    arabic: "",
    mainIMG: project53,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 50,
    english: "",
    arabic: "",
    mainIMG: project54,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 51,
    english: "",
    arabic: "",
    mainIMG: project55,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 52,
    english: "",
    arabic: "",
    mainIMG: project56,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 53,
    english: "",
    arabic: "",
    mainIMG: project57,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 54,
    english: "",
    arabic: "",
    mainIMG: project58,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 55,
    english: "",
    arabic: "",
    mainIMG: project59,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 56,
    english: "",
    arabic: "",
    mainIMG: project60,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 57,
    english: "",
    arabic: "",
    mainIMG: project61,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 58,
    english: "",
    arabic: "",
    mainIMG: project62,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 59,
    english: "",
    arabic: "",
    mainIMG: project63,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_8,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_7,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_6,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_5,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_4,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_3,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_2,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro85_1,
    interior:[],
    exterior:[],
    landscap:[]
  },



  {
    english: '',
    arabic: '',
    mainIMG: pro84_20,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_19,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_18,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_17,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_16,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_15,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_14,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_13,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_12,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_11,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_10,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_9,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_8,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_7,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_6,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_5,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_4,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_3,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_2,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro84_1,
    interior:[],
    exterior:[],
    landscap:[]
  },


  {
    english: '',
    arabic: '',
    mainIMG: pro83_21,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_20,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_19,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_18,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_17,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_16,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_15,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_14,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_13,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_12,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_11,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_10,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_9,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_8,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_7,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_6,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_5,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_4,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_3,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_2,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro83_1,
    interior:[],
    exterior:[],
    landscap:[]
  },
  
  {
    english: '',
    arabic: '',
    mainIMG: pro82_15,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_14,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_13,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_12,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_11,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_10,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_9,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_8,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_7,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_6,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_5,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_4,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_3,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_2,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro82_1,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_18,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_17,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_16,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_15,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_14,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_13,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_12,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_11,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_10,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_9,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_8,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_7,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_6,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_5,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_4,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_3,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_2,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro81_1,
    interior:[],
    exterior:[],
    landscap:[]
  },
    
  {
    english: '',
    arabic: '',
    mainIMG: pro80_2,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    english: '',
    arabic: '',
    mainIMG: pro80_1,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {   
    english: '',
    arabic: '',
    mainIMG: pro79,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
      
    english: '',
    arabic: '',
    mainIMG: pro78,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
      
      english: '',
      arabic: '',
      mainIMG: pro77,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      
      english: '',
      arabic: '',
      mainIMG: pro76,
      interior:[],
      exterior:[],
      landscap:[]
    },
   
    {
      id: 1,
      english: 'Front façade design of Salah al-Din Governorate project ',
      arabic: 'مشروع تصميم الواجهة الامامية لبناية محافظة صلاح الدين القديمة',
      mainIMG: project5,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 2,
      english: 'Designing an integrated commercial complex with clinics for doctors in Salah Al-Din-Tikrit',
      arabic: 'تصميم مجمع تجاري متكامل مع عيادات للأطباء في صلاح الدين-تكريت',
      mainIMG: project6,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 3,
      english: 'Design of Souq Al-Arbaeen in Tikrit-Salah al-Din',
      arabic: 'تصميم سوق الأربعين التجاري في صلاح الدين-تكريت',
      mainIMG: project7,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 4,
      english: 'Design of Tikrit City Center complex in Tikrit-Salah al-Din',
      arabic: 'تصميم مجمع تكريت ستي سنتر في صلاح الدين-تكريت',
      mainIMG: project8,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 5,
      english: 'Design of a commercial exhibition complex in  Tikrit-Salah al-Din ',
      arabic: 'تصميم مجمع معارض تجارية في صلاح الدين-تكريت',
      mainIMG: project9,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro86_3png,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro86_4png,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro86_5png,
      interior:[],
      exterior:[],
      landscap:[]
    },
  
    {
      english: '',
      arabic: '',
      mainIMG: pro85_25,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_24,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_23,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_22,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_21,
      interior:[],
      exterior:[],
      landscap:[]
    },
  
    {
      english: '',
      arabic: '',
      mainIMG: pro85_20,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_19,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_18,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_17,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_16,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_15,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_14,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_13,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      english: '',
      arabic: '',
      mainIMG: pro85_12,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 6,
      english: 'Preparing designs and detailed plans for a typical commercial middle school building in Salah al-Din - Tikrit',
      arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية اعدادية تجارة نموذجية في صلاح الدين-تكريت',
      mainIMG: projec10,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 7,
      english: 'Preparation of designs and detailed plans for a typical nursery building in Tikrit-Salah al-Din ',
      arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية حضانة نموذجية في صلاح الدين-تكريت',
      mainIMG: project11,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 8,
      english: 'Preparation of designs and detailed plans for the Examination Center building with guest houses in Tikrit-Salah Al-Din',
      arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية مركز فحص الامتحانات مع دار الضيافة الملحق به في صلاح الدين-تكريت',
      mainIMG: project12,
      interior:[],
      exterior:[],
      landscap:[]
    },
    

  {
    id: 40,
    english: "",
    arabic: "",
    mainIMG: project44,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 41,
    english: "",
    arabic: "",
    mainIMG: project21,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 42,
    english: "",
    arabic: "",
    mainIMG: project46,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 43,
    english: "",
    arabic: "",
    mainIMG: project47,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 44,
    english: "",
    arabic: "",
    mainIMG: project48,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 45,
    english: "",
    arabic: "",
    mainIMG: project49,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro88_7,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 30,
    english: "",
    arabic: "",
    mainIMG: project34,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 31,
    english: "",
    arabic: "",
    mainIMG: project18,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 33,
    english: "",
    arabic: "",
    mainIMG: project37,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 34,
    english: "",
    arabic: "",
    mainIMG: project38,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 35,
    english: "",
    arabic: "",
    mainIMG: project39,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 36,
    english: "",
    arabic: "",
    mainIMG: project40,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    id: 37,
    english: "",
    arabic: "",
    mainIMG: project41,
    interior:[],
    exterior:[],
    landscap:[]
  },

  {
    id: 38,
    english: "",
    arabic: "",
    mainIMG: project42,
    interior:[],
    exterior:[],
    landscap:[]
  },


  {
    id: 39,
    english: "",
    arabic: "",
    mainIMG: project43,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro88_6,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro88_5,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro88_4,
    interior:[],
    exterior:[],
    landscap:[]
  },
  
  {
    english: '',
    arabic: '',
    mainIMG: pro88_3,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro88_2,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro88_1,
    interior:[],
    exterior:[],
    landscap:[]
  },


  {
    english: '',
    arabic: '',
    mainIMG: pro87_24,
    interior:[],
    exterior:[],
    landscap:[]
  },
  {
    english: '',
    arabic: '',
    mainIMG: pro87_23,
    interior:[],
    exterior:[],
    landscap:[]
  },
    {
      id: 9,
      english: 'Preparing designs and detailed plans for a typical industry high school in Tikrit-Salah al-Din',
      arabic: 'اعداد التصاميم والمخططات التفصيلية لاعدادية صناعة نموذجية في صلاح الدين-تكريت',
      mainIMG: project13,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 10,
      english: 'Preparation of designs and detailed plans for Local Administration Engineering Department building in Salah al-Din Governorate',
      arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية قسم هندسة الإدارة المحلية في محافظة صلاح الدين',
      mainIMG: project14,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 11,
      english: 'Preparation of designs and detailed plans for the Compensation Department building in Salah al-Din Governorate',
      arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية قسم التعويضات في محافظة صلاح الدين ',
      mainIMG: project15,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 12,
      english: 'Design proposal for the Judges house complex in Tikrit-Salah al-Din ',
      arabic: 'تصميم مقترح لمجمع دور القضاة في صلاح الدين-تكريت',
      mainIMG: project16,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 13,
      english: 'Design of the external facades with the landscape of the medical complex building in Al-Tebaa Street in Tikrit-Salah Al-Din',
      arabic: "تصميم الواجهات الخارجية مع اللاتد سكيب الخاص ببناية المجمع الطبي (المشيد) في شارع الأطباء في صلاح الدين-تكريت",
      mainIMG: project4,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 14,
      english: "Integrated design and implementation of the commercial Alsa's building in Salah Al-Din-Tikrit",
      arabic: "تصميم متكامل مع التنفيذ لعمارة الساعة التجارية في صلاح الدين-تكريت",
      mainIMG: project35,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 15,
      english: 'Design of an 800 m2 villa in Baghdad',
      arabic: "تصميم فيلا بالطراز الكلاسيكي بمساحة 800 م2 في بغداد",
      mainIMG: project19,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 16,
      english: 'Interior and exterior design with implementation for a villa in Salah Al-Din',
      arabic: "تصميم خارجي وداخلي مع التنفي ,ذ لفيلا في صلاح الدين",
      mainIMG: project36,
      interior:[],
      exterior:[],
      landscap:[]
    },
   
    {
      id: 46,
      english: "",
      arabic: "",
      mainIMG: project50,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 47,
      english: "",
      arabic: "",
      mainIMG: project51,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 48,
      english: "",
      arabic: "",
      mainIMG: project52,
      interior:[],
      exterior:[],
      landscap:[]
    },
   
    {
      id: 60,
      english: "",
      arabic: "",
      mainIMG: project64,
      interior:[],
      exterior:[],
      landscap:[]
    },
    {
      id: 61,
      english: "",
      arabic: "",
      mainIMG: project65,
      interior:[],
      exterior:[],
      landscap:[]
    },
    
    {
      id: 64,
      english: "",
      arabic: "",
      mainIMG: project68,
      interior:[],
      exterior:[],
      landscap:[]
    },
    



  ];
  
// export const dataDigitalBestSeller = [
//     {
//       id: 0,
//   
//       english: 'Design of Salah Al-Din Governorate Council Project',
//       arabic: 'مشروع تصميم مجلس محافظة صلاح الدين',
//       mainIMG: 
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 1,
//   
//       english: 'Front façade design of Salah al-Din Governorate project ',
//       arabic: 'مشروع تصميم الواجهة الامامية لبناية محافظة صلاح الدين القديمة',
//       mainIMG: QF_102_mainIMG,
//       interior:[],
//       exterior:[QF_102_mainIMG,QF_102_2,QF_102_3,QF_102_4,QF_102_5,QF_102_6,QF_102_7,QF_102_8,QF_102_9,QF_102_10,QF_102_11,QF_102_12,QF_102_13,QF_102_14],
//       landscap:[]
//     },
//     {
//       id: 2,
//   
//       english: 'Designing an integrated commercial complex with clinics for doctors in Salah Al-Din-Tikrit',
//       arabic: 'تصميم مجمع تجاري متكامل مع عيادات للأطباء في صلاح الدين-تكريت',
//       mainIMG: QF_103_mainIMG,
//       interior:[],
//       exterior:[QF_103_mainIMG,QF_103_2,QF_103_3,QF_103_4,QF_103_5,QF_103_6,QF_103_7,QF_103_8,QF_103_9,QF_103_10,QF_103_11],
//       landscap:[]
//     },
//     {
//       id: 3,
//   
//       english: 'Design of Souq Al-Arbaeen in Tikrit-Salah al-Din',
//       arabic: 'تصميم سوق الأربعين التجاري في صلاح الدين-تكريت',
//       mainIMG: QF_104_mainIMG,
//       interior:[],
//       exterior:[QF_104_mainIMG,QF_104_2,QF_104_3,QF_104_4,QF_104_5,QF_104_6,QF_104_7,QF_104_8,QF_104_9],
//       landscap:[]
//     },
//     {
//       id: 4,
//   
//       english: 'Design of Tikrit City Center complex in Tikrit-Salah al-Din',
//       arabic: 'تصميم مجمع تكريت ستي سنتر في صلاح الدين-تكريت',
//       mainIMG: QF_105_mainIMG,
//       interior:[],
//       exterior:[QF_105_mainIMG,QF_105_2,QF_105_3,QF_105_4,QF_105_5,QF_105_6,QF_105_7,QF_105_8,QF_105_9,QF_105_10,QF_105_11,QF_105_12,QF_105_13,QF_105_14,QF_105_15,QF_105_16,QF_105_17,QF_105_18,QF_105_19,QF_105_20,QF_105_21],
//       landscap:[]
//     },
//     {
//       id: 5,
//   
//       english: 'Design of a commercial exhibition complex in  Tikrit-Salah al-Din ',
//       arabic: 'تصميم مجمع معارض تجارية في صلاح الدين-تكريت',
//       mainIMG: QF_106_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 6,
//   
//       english: 'Preparing designs and detailed plans for a typical commercial middle school building in Salah al-Din - Tikrit',
//       arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية اعدادية تجارة نموذجية في صلاح الدين-تكريت',
//       mainIMG: QF_107_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 7,
//   
//       english: 'Preparation of designs and detailed plans for a typical nursery building in Tikrit-Salah al-Din ',
//       arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية حضانة نموذجية في صلاح الدين-تكريت',
//       mainIMG: QF_108_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 8,
//   
//       english: 'Preparation of designs and detailed plans for the Examination Center building with guest houses in Tikrit-Salah Al-Din',
//       arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية مركز فحص الامتحانات مع دار الضيافة الملحق به في صلاح الدين-تكريت',
//       mainIMG: QF_109_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 9,
//   
//       english: 'Preparing designs and detailed plans for a typical industry high school in Tikrit-Salah al-Din',
//       arabic: 'اعداد التصاميم والمخططات التفصيلية لاعدادية صناعة نموذجية في صلاح الدين-تكريت',
//       mainIMG: QF_110_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 10,
//   
//       english: 'Preparation of designs and detailed plans for Local Administration Engineering Department building in Salah al-Din Governorate',
//       arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية قسم هندسة الإدارة المحلية في محافظة صلاح الدين',
//       mainIMG: QF_111_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 11,
//   
//       english: 'Preparation of designs and detailed plans for the Compensation Department building in Salah al-Din Governorate',
//       arabic: 'اعداد التصاميم والمخططات التفصيلية لبناية قسم التعويضات في محافظة صلاح الدين ',
//       mainIMG: QF_112_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 12,
//   
//       english: 'Design proposal for the Judges house complex in Tikrit-Salah al-Din ',
//       arabic: 'تصميم مقترح لمجمع دور القضاة في صلاح الدين-تكريت',
//       mainIMG: QF_113_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 13,
//   
//       english: 'Design of the external facades with the landscape of the medical complex building in Al-Tebaa Street in Tikrit-Salah Al-Din',
//       arabic: "تصميم الواجهات الخارجية مع اللاتد سكيب الخاص ببناية المجمع الطبي (المشيد) في شارع الأطباء في صلاح الدين-تكريت",
//       mainIMG: QF_114_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 14,
//   
//       english: "Integrated design and implementation of the commercial Alsa's building in Salah Al-Din-Tikrit",
//       arabic: "تصميم متكامل مع التنفيذ لعمارة الساعة التجارية في صلاح الدين-تكريت",
//       mainIMG: QF_115_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 15,
//   
//       english: 'Design of an 800 m2 villa in Baghdad',
//       arabic: "تصميم فيلا بالطراز الكلاسيكي بمساحة 800 م2 في بغداد",
//       mainIMG: QF_116_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 16,
//   
//       english: 'Interior and exterior design with implementation for a villa in Salah Al-Din',
//       arabic: "تصميم خارجي وداخلي مع التنفي ,ذ لفيلا في صلاح الدين",
//       mainIMG: QF_117_mainIMG_interior,
//       interior:[QF_117_mainIMG_interior,QF_117_2_interior,QF_117_3_interior,QF_117_4_interior,QF_117_5_interior,QF_117_6_interior,QF_117_7_interior,QF_117_8_interior],
//       exterior:[QF_117_mainIMG_exterior,QF_117_2_exterior,QF_117_3_exterior,QF_117_4_exterior,QF_117_5_exterior,QF_117_6_exterior,QF_117_7_exterior,QF_117_8_exterior],
//       landscap:[]
//     },
//     {
//       id: 17,
//   
//       english: 'Interior and exterior design and landscape with implementation for a villa in Salah Al-Din',
//       arabic: "تصميم خارجي وداخلي و لاند سكيب مع التنفيذ لفيلا في صلاح الدين",
//       mainIMG: QF_118_mainIMG_interior,
//       interior:[QF_118_mainIMG_interior,QF_118_2_interior,QF_118_3_interior,QF_118_4_interior,QF_118_5_interior,QF_118_6_interior,QF_118_7_interior,QF_118_8_interior],
//       exterior:[QF_118_mainIMG_exterior,QF_118_2_exterior,QF_118_3_exterior,QF_118_4_exterior,QF_118_5_exterior],
//       landscap:[QF_118_mainIMG_landscap,QF_118_2_landscap,QF_118_3_landscap,QF_118_4_landscap,QF_118_5_landscap,QF_118_6_landscap,QF_118_7_landscap,QF_118_8_landscap,QF_118_9_landscap,QF_118_10_landscap,QF_118_11_landscap,QF_118_12_landscap,QF_118_13_landscap]
//     },
//     {
//       id: 18,
//   
//       english: 'Interior and exterior design with implementation for a villa in Salah Al-Din',
//       arabic: "تصميم خارجي وداخلي مع التنفيذ لفيلا في صلاح الدين",
//       mainIMG: QF_119_mainIMG_interior,
//       interior:[QF_119_mainIMG_interior,QF_119_2_interior,QF_119_3_interior,QF_119_4_interior,QF_119_5_interior,QF_119_6_interior,QF_119_7_interior,QF_119_8_interior],
//       exterior:[QF_119_mainIMG_exterior,QF_119_2_exterior,QF_119_3_exterior,QF_119_4_exterior,QF_119_5_exterior,QF_119_6_exterior,QF_119_7_exterior,QF_119_8_exterior],
//       landscap:[]
//     },
//     {
//       id: 19,
//   
//       english: "Exterior design for a villa in Erbil",
//       arabic: "تصميم خارجي وداخلي مع التنفيذ لفيلا في صلاح الدين",
//       mainIMG: QF_120_mainIMG,
//       interior:[],
//       exterior:[QF_120_mainIMG,QF_120_2,QF_120_3,QF_120_4,QF_120_5,QF_120_6,QF_120_7],
//       landscap:[]
//     },

//         {
//       id: 20,
//   
//       english: "Interior and exterior design with implementation for a villa in Tikrit-Salah Al-Din",
//       arabic: "تصميم خارجي وداخلي مع التنفيذ لفيلا في صلاح الدين-تكريت",
//       mainIMG: QF_121_mainIMG_exterior,
//       interior:[QF_121_mainIMG_interior,QF_121_2_interior,QF_121_3_interior,QF_121_4_interior,QF_121_5_interior,QF_121_6_interior,QF_121_7_interior,QF_121_8_interior],
//       exterior:[QF_121_mainIMG_exterior,QF_121_2_exterior,QF_121_3_exterior,QF_121_4_exterior,QF_121_5_exterior,QF_121_6_exterior],
//       landscap:[]
//     },

//     {
//       id: 21,
//   
//       english: "Interior design for a house in Baiji-Salah Al-Din",
//       arabic: "تصميم داخلي لبيت مشيد في صلاح الدين-بيجي",
//       mainIMG: QF_122_mainIMG,
//       exterior:[],
//       interior:[],
//       landscap:[]
//     },

//     {
//       id: 22,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_123_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 23,
//   
//       english: "Interior design for the main sales center of Asiacell Telecom Company in Kirkuk",
//       arabic: "تصميم داخلي للمركز الرئيسي للمبيعات الخاص بشركة اسيا سيل للاتصالات في كركوك",
//       mainIMG: QF_124_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 24,
//   
//       english: "Design and implementation of Al-Mukhtar Central Pharmacy in Kirkuk",
//       arabic: "تصميم وتنفيذ صيدلية المختار المركزية في كركوك",
//       mainIMG: QF_125_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 25,
//   
//       english: "Design and implementation of Al-Hayba pharmacy in Kirkuk",
//       arabic:  "تصميم وتنفيذ صيدلية الهيبة المركزية في كركوك",
//       mainIMG: QF_126_mainIMG,
//       interior:[],
//       exterior:[QF_126_mainIMG,QF_126_2,QF_126_3,QF_126_4],
//       landscap:[]
//     },


//     {
//       id: 26,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_127_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },



//     {
//       id: 27,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_128_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 28,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_129_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
    
  
//     {
//       id: 29,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_130_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 30,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_131_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 31,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_132_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },



//     {
//       id: 32,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_133_mainIMG,
//       interior:[],
//       exterior:[QF_133_mainIMG,QF_133_2,QF_133_3,QF_133_4],
//       landscap:[]
//     },

//     {
//       id: 33,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_134_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 34,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_135_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 35,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_136_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 36,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_137_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },
//     {
//       id: 37,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_138_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 38,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_142_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },


//     {
//       id: 39,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_143_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },


//     {
//       id: 40,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_144_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 41,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_145_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 42,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_146_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },

//     {
//       id: 43,
//   
//       english: "",
//       arabic: "",
//       mainIMG: QF_147_mainIMG,
//       interior:[],
//       exterior:[],
//       landscap:[]
//     },



//   ];